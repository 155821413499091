import { createRouter, createWebHistory } from "vue-router";
import Store from './store/index.js'
import Commons from "./utils/commons.js";
import LocalStorage from './utils/localStorage';
import Notification from './utils/notification';
import RedirectUrl from './utils/redirectUrl';
import i18n from './i18n';
import { ability } from './config/ability.js';


/**
 * Dynamic import of the components.
 * The component to load (should be a Promise).
 *
 * @see  https://router.vuejs.org/guide/advanced/lazy-loading.html
 */
const AcceptInvitationGuest = () => import('./views/pages/AcceptInvitationGuest.vue');
const ApplicationIntegration = () => import('./views/pages/ApplicationIntegration.vue');
const CustomSignaturePage = () => import('./views/components/signature/signaturePage/CustomSignaturePage.vue');
const DeactivationFeedback = () => import('./views/pages/DeactivationFeedback.vue');
const EditSummary = () => import('./views/components/workflowTemplate/EditSummary.vue');
const EditTemplate = () => import('./views/pages/EditTemplate.vue');
const EmailUnsubscribe = () => import('./views/pages/EmailUnsubscribe.vue');
const EmailUpdate = () => import('./views/pages/EmailUpdate.vue');
const EmptyRouterView = () => import('./views/pages/EmptyRouterView.vue');
const ExternalApplicationLogin = () => import('./views/pages/ExternalApplicationLogin.vue');
const FindOrganizations = () => import('./views/pages/FindOrganizations.vue');
const Forbidden = () => import('./views/pages/Forbidden.vue');
const Home = () => import('./views/pages/Home.vue');
const Login = () => import('./views/pages/login_signup/Login.vue');
const LoginRegisteredOrg = () => import('./views/pages/login_signup/components/LoginRegisteredOrg.vue');
const NotFound = () => import('./views/pages/NotFound.vue');
const OAuthIntegration = () => import('./views/pages/OAuthIntegration.vue');
const OAuthStorageIntegration = () => import('./views/pages/OAuthStorageIntegration.vue');
const Organization =  () => import('./views/pages/Organization.vue');
const OrganizationEmailConfiguration = () => import('./views/pages/OrganizationEmailConfiguration.vue');
const OrganizationNameForm = () => import('./views/pages/login_signup/components/OrganizationNameForm.vue');
const OrganizationPicker = () => import('./views/pages/OrganizationPicker.vue');
const OrganizationSurveyForm = () => import('./views/pages/login_signup/components/OrganizationSurveyForm.vue');
const Project = () => import('./views/pages/Project.vue');
const ProjectCollaborators = () => import('./views/components/project/ProjectCollaborators.vue');
const ProjectDashboard = () => import('./views/components/project/ProjectDashboard.vue');
const ProjectDocuments = () => import('./views/components/project/ProjectDocuments.vue');
const ProjectLog = () => import('./views/components/project/ProjectLog.vue');
const ProjectTask = () => import('./views/components/project/ProjectTask.vue');
const ProjectTasks = () => import('./views/components/project/ProjectTasks.vue');
const ProjectWorkflow = () => import('./views/components/project/ProjectWorkflow.vue');
const Projects = () => import('./views/pages/Projects.vue');
const QuickCombine = () => import('./views/components/project/quickStart/QuickCombine.vue');
const QuickFile = () => import('./views/components/project/quickStart/QuickFile.vue');
const QuickNotifications = () => import('./views/components/project/quickStart/QuickNotifications.vue');
const QuickSign = () => import('./views/components/project/quickStart/QuickSign.vue');
const QuickStep = () => import('./views/components/project/quickStart/QuickStep.vue');
const QuickTasks = () => import('./views/components/project/quickStart/QuickTasks.vue');
const Signup = () => import('./views/pages/login_signup/Signup.vue');
const RegisterOrganization = () => import('./views/pages/login_signup/RegisterOrganization.vue');
const SimplerUXProject = () => import('./views/components/project/quickStart/SimplerUXProject.vue');
const SlackInstallation = () => import('./views/pages/SlackInstallation.vue');
const TaskShareView = () => import('./views/pages/TaskShareView.vue');
const Templates = () => import('./views/pages/Templates.vue');
const User = () => import('./views/pages/User.vue');
const Users = () => import('./views/pages/Users.vue');


let router = createRouter({
  history: createWebHistory('/'),
  routes: [

    {
      path: "",
      name: "root",
      // Routing decisions for this path are handled in beforeEach
    },

    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: {
        authorize: false
      },
    },

    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        authorize: false
      },
    },

    {
      path: '/oauth-integration',
      name: 'oauthIntegration',
      component: OAuthIntegration,
      meta: {
        authorize: true
      },
    },

    {
      path: '/slack-installation', // TODO: Consider changing to "slack-integration".
      name: 'slackInstallation',
      component: SlackInstallation,
      meta: {
        authorize: false
      },
    },

    {
      path:'/external-app-login',
      name:'ExternalApplicationLogin',
      component: ExternalApplicationLogin,
      meta: {
        authorize: false
      },
      props:true,
    },

    {
      path: '/oauth-storage-integration',
      name: 'oauthStorageIntegration',
      component: OAuthStorageIntegration,
      meta: {
        authorize: true
      },
    },

    {
      path: '/app-integration/:requestId',
      name: 'applicationIntegration',
      component: ApplicationIntegration,
      meta: {
        authorize: false,
      },
      props:true,
    },

    {
      path: '/email-update',
      name: 'emailUpdate',
      component: EmailUpdate,
      meta: {
        authorize: false
      }
    },

    {
      path: '/email-unsubscribe',
      name: 'EmailUnsubscribe',
      component: EmailUnsubscribe,
      meta: {
        authorize: false
      },
      // Map route query params to props.
      props: route => Object.assign({}, route.query, route.params)
    },

    {
      path:'/org/:urlName?',
      name:'OrganizationPicker',
      component: OrganizationPicker,
      props:true,
    },

    {
      path:'/register-org',
      name:'registerOrg',
      component: RegisterOrganization,
      props: route => Object.assign({}, route.query),
      meta: {
        authorize: false,
      },
      children:[
        {
          path:'name',
          name: 'setOrgName',
          component: OrganizationNameForm,
          meta: {
            authorize: false,
          },
        },
        {
          path:'survey',
          name: 'orgSurvey',
          component: OrganizationSurveyForm,
          props: route => Object.assign({}, route.query),
          meta: {
            authorize: false,
          },
        },
        {
          path:'login',
          name: 'loginRegistered',
          component: LoginRegisteredOrg,
          meta: {
            authorize: false,
          },
        },
      ]
    },

    {
      path:'/organization-find',
      name:'FindOrganizations',
      component: FindOrganizations,
    },

    {
      path: '/:organizationId',
      //name: 'org', // This produces a rather prominent warning for some reason
      component: EmptyRouterView,
      meta: {
        authorize: true
      },

      children:[

        {
          path:'',
          component: Home,
          meta: {
            authorize: true,
            analytics_title: "Home"
          },
        },

        {
          path: 'home',
          name: 'home',
          component: Home,
          props: route => Object.assign({}, route.query),
          meta: {
            authorize: true,
            analytics_title: "Home"
          },
        },

        {
          path: 'share-task/:taskShareToken',
          name: 'taskShareView',
          component: TaskShareView,
          meta: {
            authorize: false
          },
          props: true,
          analytics_title: "Task Share View"
        },

        {
          path: 'projects/:filter?', //optional :filter? parameter
          name: 'projects',
          // route level code-splitting
          // which is lazy-loaded when the route is visited.
          component: Projects,
          props: true,
          meta: {
            authorize: true,
            analytics_title: "Projects"
          },
        },
        {
          path: 'templates/:workflowId?', //optional worlflowId parameter',
          name: 'templates',
          // route level code-splitting
          // which is lazy-loaded when the route is visited.
          component: Templates,
          props: true,
          meta: {
            authorize: true,
            analytics_title: "Workflows"
          }
        },
        {
          path: 'users',
          name: 'users',
          // Route level code-splitting which is lazy-loaded when the route is
          // visited.
          component: Users,
          props: true,
          meta: {
            authorize: true,
            analytics_title: "Users"
          }
        },
        {
          path: 'organization',
          name: 'organization',
          // Route level code-splitting which is lazy-loaded when the route is
          // visited.
          component: Organization,
          props: true,
          meta: {
            authorize: true,
            analytics_title: "Organization"
          }
        },
        {
          path: 'email-configuration',
          name: 'organizationEmailConfiguration',
          component: OrganizationEmailConfiguration,
          props: true,
          meta: {
            authorize: true,
            analytics_title: "Organization - Email Customization Page"
          }
        },
        {
          path: 'custom-signature-page',
          name: 'customSignaturePage',
          // Route level code-splitting which is lazy-loaded when the route is
          // visited.
          component: CustomSignaturePage,
          props: true,
          meta: {
            authorize: true,
            analytics_title: "Organization - Custom Signature Page"
          }
        },
        {
          path: 'profile',
          name: 'profile',
          // Route level code-splitting which is lazy-loaded when the route is
          // visited.
          component: User,
          props: true,
          meta: {
            authorize: true,
            analytics_title: "Profile"
          },
          children:[
            {
              path: 'deactivation',
              name: 'deactivationFeedback',
              // Route level code-splitting which is lazy-loaded when the route is
              // visited.
              component: DeactivationFeedback,
              props: true
            },
          ]
        },

        {
          // Accepting an organization invitation ("Sign in" email option),
          // if the user already has an account in another organization
          // router.beforeEach() redirects user to Log in
          path: 'invitation/:invitationId',
          name: 'organizationUserInvitation',
          // Component removed because a User redirects to Login page
          // in router.beforeEach() (see DOS-1192)
          props: true,
          meta: {
            authorize: true
          }
        },

        {
          // Optional "taskId" enables redirecting the user to a specifc task
          // afer accepting the invitation.

          path: 'invitation/:invitationId/guest/:taskId?/:viewMode?',
          name: 'invitationGuest',
          component: AcceptInvitationGuest,
          props: true,
          meta: {
            authorize: false // No account required
          }
        },

        {
          path: 'invitation/:invitationId/project',
          name: 'invitation',
          // Component removed because a User redirects to Login page
          // in router.beforeEach()
          props: true,
          meta: {
            authorize: true,
          }
        },
        {
          path: 'quick/:projectId',
            name: 'quickProject',
            //DOS-1002 redirection is now done in Project.vue#created
            //redirect: 'project/:projectId/dashboard',
            component: SimplerUXProject,
            props: true,
            meta: {
              analytics_title: "Project"
            },
            children: [{
              path: 'file',
              name: 'quickFile',
              component: QuickFile,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - File"
              },
            },
            {
              path: 'tasks',
              name: 'quickTasks',
              component: QuickTasks,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Tasks"
              }
            },
            {
              path: 'notifications',
              name: 'quickNotifications',
              component: QuickNotifications,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Log"
              }
            },
            {
              path: 'edit/:stepId',
              name: 'quickEdit',
              component: QuickStep,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Edit"
              }
            },
            {
              path: 'review/:stepId',
              name: 'quickReview',
              component: QuickStep,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Review"
              }
            },
            {
              path: 'sign/:stepId',
              name: 'quickSign',
              component: QuickSign,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Sign"
              }
            },
            {
              path: 'approve/:stepId',
              name: 'quickApprove',
              component: QuickStep,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Approve"
              }
            },
            {
              path: 'deliver/:stepId',
              name: 'quickDeliver',
              component: QuickStep,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Deliver"
              }
            },
            {
              path: 'combine/:stepId',
              name: 'quickCombine',
              component: QuickCombine,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Combine"
              }
            },
          ]
        },
        {
          path: 'project/:projectId',
          name: 'project',
          //DOS-1002 redirection is now done in Project.vue#created
          //redirect: 'project/:projectId/dashboard',
          component: Project,
          props: true,
          meta: {
            analytics_title: "Project"
          },
          children: [{
              path: 'dashboard',
              name: 'projectDashboard',
              component: ProjectDashboard,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Dashboard"
              },
            },
            {
              path: 'workflow',
              name: 'projectWorkflow',
              component: ProjectWorkflow,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Workflow"
              }
            },
            {
              /**
               * Tasks path.
               *
               * Parameters:
               * - "taskId" (optional): task id to select which Task to open
               * - "viewMode" (optional): which view mode to use (options: "full-screen")
               */
              path: 'tasks/:taskId?/:viewMode?',
              name: 'projectTasks',
              component: ProjectTasks,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Tasks"
              }
            },
            {
              /**
               * A Task path. Unlike "Tasks" path, this will open and show a single Task.
               */
              path: 'task/:taskId?',
              name: 'projectTask',
              component: ProjectTask,
              props: (route) => ({
                fullscreen: route.query.fullscreen === 'true', // Equal 'true' prevents 'Expected Boolean, got String' warning.
                ...route.params   // All other parameters.
              }),
              meta: {
                authorize: true,
                analytics_title: "Project - Task"
              }
            },
            {
              path: 'collaborators',
              name: 'projectCollaborators',
              component: ProjectCollaborators,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Collaborators"
              }
            },
            {
              /**
               * Files path.
               *
               * Parameters:
               * - "fileId" (optional): file id to select and preview by default
               */
              path: 'files/:fileId?',
              name: 'projectDocuments',
              component: ProjectDocuments,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Files"
              }
            },
            {
              /**
               * Project Log path.
               *
               * Parameters:
               * - none
               */
              path: 'log',
              name: 'projectLog',
              component: ProjectLog,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Project - Log"
              }
            }
          ]
        },
        {
          path: 'template/:projectId',
          name: 'editTemplate',
          component: EditTemplate,
          props: true,
          meta: {
            authorize: true,
            analytics_title: "Template"
          },
          children: [{
              path: 'workflow',
              name: 'templateWorkflow',
              component: EditSummary,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Template - Workflow"
              }
            },
            {
              /**
               * Files path.
               *
               * Parameters:
               * - "fileId" (optional): file id to select and preview by default
               */
              path: 'files/:fileId?',
              name: 'templateDocuments',
              component: ProjectDocuments,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Template - Files"
              }
            },
            {
              path: 'collaborators',
              name: 'templateCollaborators',
              component: ProjectCollaborators,
              props: {
                default: true,
                isTemplate: true // Inform the component about template.
              },
              meta: {
                authorize: true,
                analytics_title: "Template - Collaborators"
              }
            },
            {
              /**
               * Project Log path.
               *
               * Parameters:
               * - none
               */
              path: 'log',
              name: 'templateLog',
              component: ProjectLog,
              props: true,
              meta: {
                authorize: true,
                analytics_title: "Template - Log"
              }
            }
          ]
        }
      ]
    },

    {
      path: '/404',
      name: '404',
      component: NotFound,
    },
    {
      name:'403',
      path: '/403',
      component: Forbidden,
      props: true,
    },
    {
      path: '/:pathMatch(.*)*',
      name:'404',
      component: NotFound,
    }, // if no match, go back to 404
    {
      path: '/msg/:notificationToken',
      name: 'notification',
    },
  ],
  scrollBehavior(to) {
    // Note: Do note that the scrolling with .hash does not work if the page loads content
    //       dynamically and the target element is not available when the route changes.
    //       For components/pages that this causes problems the issue can be handled in
    //       component itself, see Organization.vue#created for example.
    // TODO: Consider improving this, for example having a mixin that performs the scroll-to-hash
    //       functionality globally when a Vue component is mounted.

    if(to.hash)
      return { el: to.hash };

    return { top: 0 };
  }
});

router.beforeEach(async (to, from) => {
  // Do not proceed if the service is undergoing maintenance.
  if(Commons.isUnderMaintenance())
    return false;

  // Do not proceed with authorization if path is register-org.
  // The authorization logic is handled inside RegisterOrganization.vue
  if(to.path.startsWith("/register-org"))
    return

  // If the user is accessed via a notification short link, redirect to the full URL (DOS-3075)
  if(to.name == 'notification') {
    try {
      await RedirectUrl.redirectToNotificationUrl(to.params.notificationToken);
    }
    catch(error) {
      return ({
        name: '404'
      });
    }
  }

  // Log out user when navigating to "taskShareView"
  await Store.dispatch("logOutWhenNavigatingToTaskShareView",to);

  // 1. Check for pending logins
  //
  // This happens when user has completed their login and gets redirected
  // back to this application.
  let hasPendingLogin =
    await Store.dispatch("checkForLoginToken");

  // 2. Resolve user information, if available
  //
  // If user is logged in, isAuthenticated will change to true after this.
  if(!Store.getters.isAuthenticated) {
    await Store.dispatch("loadUserInformation"); // Also resolves Store.getters.organizationId
  }

  if(hasPendingLogin === true) {

    let currentUrl = new URL(window.location.href);

    // If we have a page we wanted to navigate to before login, load it
    let pageBeforeLogin = sessionStorage.getItem("flowPageBeforeLogin")
                          || currentUrl.searchParams.get('flowPageBeforeLogin');

    if(pageBeforeLogin) {
      sessionStorage.removeItem("flowPageBeforeLogin");
      const loggedInOrgId = Store.getters.getUserOrganizationId; // The organization to which the user is logged in.
      const beforeLoginOrgId = pageBeforeLogin.split('/')[1]; // The organization to which the user will be redirected.
      // Check that a logged in orgId and orgId of the page before log in the same (DOS-3628)
      if(loggedInOrgId === beforeLoginOrgId) {
        return ({
          path: pageBeforeLogin,
        });
      } else {
        return ({
          name: 'home',
          params: {
            organizationId: loggedInOrgId,
          },
          replace: true, // DOS-2768: Avoid polluting back history
        });
      }
    }
    else if(currentUrl.searchParams.get('newOrganization') == "true") {
      return ({
        name: 'home',
        params: {
          organizationId: Store.getters.organizationId,
        },
        query: { welcome: "newOrganization"}
      });
    }
    else if(currentUrl.searchParams.get('firstLogin') == "true") {
      return ({
        name: 'home',
        params: {
          organizationId: Store.getters.organizationId,
        },
        query: { welcome: "firstLogin"}
      });
    } else {
      return ({
        path: new URL(window.location.href).pathname.toString(), // Remove login token and proceed
        // TODO: Does not actually remove the token before next beforeEach. Consider using replaceState like below
      });
    }
  } else if(typeof hasPendingLogin == "string"){ // Error state?
    //TODO: Would be more accurate to reroute to the organization that was targeted, but it is not
    //      directly available at this point.
    let message;

    switch(hasPendingLogin) {
      case "UNAUTHORIZED":
        message = i18n.global.t("router.notification.not_authorized_sign_in");
        break;
      case "ENFORCE_IDENTITY_PROVIDER":
        message = i18n.global.t("router.notification.enforce_identity_provider");
        break;
      case "ERROR":
      default:
        message = i18n.global.t("router.notification.error");
        break;
    }

    UIkit.notification(message, {status:Notification.STATUS.WARNING});

    // Clear url parameters so that we do not end up in a loop
    history.replaceState({},"",window.location.pathname)

    return ({
      name: 'signup',
    });
  }

  // 3. If no specific target is requested, resolve the default one here

  if(to.name == 'root') {
    if(Store.getters.isAuthenticated) {
      const userRole = Store.getters.getUserRole;
      if(userRole === 'GUEST') {
        return ({
          name: 'projects',
          params: {
            organizationId: Store.getters.organizationId,
          },
          replace: true, // DOS-2768: Avoid polluting back history
        });
      } else {
        return ({
          name: 'home',
          params: {
            organizationId: Store.getters.organizationId,
          },
          replace: true, // DOS-2768: Avoid polluting back history
        });
      }
    } else {
      return ({
        name: 'signup',
        replace: true, // DOS-2768: Avoid polluting back history
      });
    }
  }

  // 4. Get user project role and apply abilities,
  //    if a user navigates to a project.

  const isProjectPath =
        to.matched.some(el => el.path == '/:organizationId/project/:projectId');
  const isProjectPathQuick =
        to.matched.some(el => el.path == '/:organizationId/quick/:projectId');
  // Don't send a request if the user is not logged in (see DOS-1154)
  if ((isProjectPath || isProjectPathQuick) && Store.getters.isAuthenticated) {
    // Don't request a project role if user navigates between project tabs.
    // This causes a navigation delay on slow network connection.
    if((to.params.projectId != from.params.projectId) || (isProjectPath != isProjectPathQuick)) {
      const response = await Store.dispatch('getUserProjectRole', to.params.projectId);

      if(response.status == "403"){
        return ({
          name: '403'
        });
      }
    }
  }

  let invitationId = to.params.invitationId;
  let pageBeforeLogin = to.fullPath;
  let paramsorganizationId = to.params.organizationId;
  let loginname = to.query.loginname;
  let paramsProjectId = to.params.projectId;
  let paramsTaskId = to.params.taskId;

  // Keep login name in session to prevent value loss
  // while multiple redirection attempts.
  if(loginname){
    sessionStorage.setItem("loginname",String(loginname));
  }
  else{
    loginname = sessionStorage.getItem("loginname");
    sessionStorage.removeItem("loginname");
  }

  // 5. If target page requires authorization, check for permissions
  if(to.meta.authorize) {

    // User accepts an invitation with 'Sign in' option.
    // Log out if LocalStorage contains info about logged-in user
    if(LocalStorage.getUserToken() && (to.name == "invitation"
        || to.name == "organizationUserInvitation")) {
      await Store.dispatch("authLogout");
    }

    // OAuth integration flow but for the Sign-Only service.
    // The user is should not be logged into to Flow Website but should be to the Sign-Only Website,
    // so there's no need to log them in and we can simply redirect them.
    // Note: The user would've been redirected here from an external service because this is the
    //       defined URI end-point for the service.
    if(to.name === 'oauthIntegration' && to.query.state &&
       to.query.state.startsWith('sign-only.')) {
      Store.dispatch("redirectToSignOnlyOAuth", to.query);
    }

    // Redirect to login page if not logged in
    if(!LocalStorage.getUserToken() || !Store.getters.isAuthenticated) {
      // User is going to specific org pages then redirect user to that organization Log in page

      // Check if user has to login with external application token, then redirect to
      // external app login page.
      let params = {
        pageBeforeLogin: pageBeforeLogin,
        taskId: paramsTaskId,
        projectId: paramsProjectId,
        organizationId: paramsorganizationId
      };
      // DOS-3527: An invitation can not be accepted by a user that has no password - do not show
      //           the "sign in with an external app" -option if accepting an invitation.
      if (!invitationId && paramsTaskId) { // paramsTaskId cannot be NULL, otherwise it will throw an error.
        try {
          let externalAppLoginInfo = await Store.dispatch("getExternalAppLoginInfo", params);

          if (externalAppLoginInfo.externalApps.length > 0) {
            sessionStorage.setItem('flowPageBeforeLogin', pageBeforeLogin);
            return ({
              name: 'ExternalApplicationLogin',
              params: {
                paramsExternalAppLoginInfo: externalAppLoginInfo,
                paramsPageBeforeLogin: pageBeforeLogin,
                paramsTaskId: paramsTaskId,
                paramsProjectId: paramsProjectId,
                paramsOrganizationId: paramsorganizationId,
                paramsInvitationId: invitationId
              }
            })
          }
        }
        catch (error) {
          return ({
            name: 'signup',
            replace: true,
          });
        }
      }
      if(paramsorganizationId){
        let params = {
          invitationId: invitationId,
          organizationId: paramsorganizationId
        };
        if(loginname){
          params.loginname = loginname;
        }
        // Do not redirect to invitation pages after login, but other pages should be re-directed to.
        if(to.name != "organizationUserInvitation" && to.name != "invitation")
          sessionStorage.setItem("flowPageBeforeLogin",pageBeforeLogin);

        if(to.name == "profile") {
          Store.dispatch("redirectToLogin", params);
        }
        else if(to.name == "organizationUserInvitation") {
          Store.dispatch("redirectToLogin", params);
        }
        else if(to.name == "projectTasks" || to.name == "project") {
          Store.dispatch("redirectToLogin", params);
        }
        else {
          UIkit.notification(i18n.global.t("router.notification.not_authorized"),
          {status:Notification.STATUS.WARNING});

          // Delay the rerouting to sign-in page in order to keep the notification visible for a while.
          window.setTimeout(function() {
          Store.dispatch("redirectToLogin", params);
          },3000);
        }
        return false;
      }
      else{
        sessionStorage.setItem("flowPageBeforeLogin",pageBeforeLogin);
        console.log("Redirecting to login, then coming back to",
                    pageBeforeLogin);
        return ({
          name: 'login'
        });
      }
    }

    if(Store.getters.getUserRole == "SIGN_ONLY") {
      let params = {
        pathName: to.name,
        projectId: to.params.projectId
      }
      await Store.dispatch("redirectToSignOnlyWebsite", params);
    }

    let organizationId = Store.getters.getUserOrganizationId;

    // User is navigating to a different organization page than where they are logged in to.

    if(paramsorganizationId && paramsorganizationId != organizationId && to.query.reload != 'true'){

      // If the user is trying to navigate to another organization (for example, clicks the "Back" button in the browser),
      // redirect to the home page of the currently logged in organization
      console.error("Tried to navigate to another organization");
      return ({
        name: '403',
      });
    }

    // Check whether the user is allowed to access the page
    if (!ability.can('access', to.name)) {
      console.error("Cannot access ",to.name,to.path);
      return ({
        name: '403',
      });
    }
  }

  // 6. Before routing, if there is an UI update waiting, perform a location assign (that causes
  // page reload) to apply the new version.
  // Note: See DOS-1691.

// Commented out for now due to reload loop in cloud deployment updates. See DOS-2870.
//  if(Store.getters['ui/isNewVersionAvailable']) {
//    window.location.assign(to.fullPath);
//    return;
//  }

  // 7. Perform other actions

  // Giving an optional parameter of "uiBusy" as "true"
  // e.g. <router-link :to="{ name:'xxxx', params:{uiBusy: true} }">
  // will add a busy item called "loadPage".
  //
  // This can be utilized to show a loading spinner
  // in the page of your choice using:
  // e.g. if (this.$store.getters['ui/isBusy'] == true)
  //
  // And please remove the busy item after that using:
  // this.$store.commit("ui/REMOVE_BUSY_ITEM","loadPage");
  if(to.query && to.query.uiBusy == 'true')
    Store.commit("ui/ADD_BUSY_ITEM","loadPage");

  // 8. Get organization groups if user is accessing to projects, projectDashboard or projectWorkflow
  // Fetching groups is also required when user accesses the workflow edit page (DOS-3107).
  if(Store.getters.getUserRole === 'ORGANIZATION_ADMIN' &&
    (to.name == 'projects' ||
     to.name == 'projectDashboard' ||
     to.name == 'projectWorkflow' ||
     to.name == 'projectTasks' ||
     to.name == 'projectCollaborators' ||
     to.name == 'projectDocuments' ||
     to.name == 'projectLog' ||
     to.name == 'home' ||
     to.name == 'users' ||
     to.name == 'organization' ||
     to.name == 'templates' ||
     to.name == 'templateWorkflow' ||
     to.name == 'templateLog'))
    await Store.dispatch("getOrganizationGroups");

  // Show first Workflow File when entering Files page
  // See DOS-3434 and DOS-3694
  if((to.name == 'projectDocuments' && from.name != 'projectDocuments') || (to.name == 'templateDocuments' && from.name != 'templateDocuments'))
    to.query.openWorkflowFile = true;

})

// Components may add e.g. afterEach handlers to the exported router

export default router;
